import $ from 'jquery';
import * as MOJFrontend from '@ministryofjustice/frontend';
import * as GOVUKFrontend from 'govuk-frontend';
window.$ = $;
window.GOVUKFrontend = GOVUKFrontend;
window.MOJFrontend = MOJFrontend;

require('form-wizard-components/all');

window.GOVUKFrontend.initAll();
window.MOJFrontend.initAll();

window.App = {};
window.App.ListAppointments =
  require('./modules/list-appointments.js').ListAppointments;
