import $ from 'jquery';

export class ListAppointments {
  constructor({
    container = '',
    noAppointmentsText = 'No appointments',
    timeText = 'Time',
    nameText = 'Name',
    appointmentIdText = 'Appointment ID',
    appointmentTypeText = 'Appointment type',
    showPastAppointmentsText = 'Show past appointments',
  } = {}) {
    this.container = $(container);

    if (!this.container.length) return;

    this.noAppointmentsText = noAppointmentsText;
    this.timeText = timeText;
    this.nameText = nameText;
    this.appointmentIdText = appointmentIdText;
    this.appointmentTypeText = appointmentTypeText;
    this.showPastAppointmentsText = showPastAppointmentsText;

    this.resultsContainer = this.container.find('#results');
    this.showPast = window.location.search.includes('past=true');
    this.pastDateOptions = $(
      'select[name="month"] option[data-past=true]'
    ).clone();

    this.init();
  }

  init() {
    this.hideSearchButton();
    this.filterPastDates();
    this.createPastToggleCheckbox();
    this.bindEvents();
  }

  hideSearchButton() {
    this.container.find('button#search').addClass('govuk-!-display-none');
  }

  bindEvents() {
    $('select[name="month"]').on('change', this.onChange.bind(this));
    $('input[name="past"]').on('change', this.toggleShowPast.bind(this));
  }

  filterPastDates() {
    if (!this.showPast) {
      $('select[name="month"] option[data-past=true]').remove();
    }
  }

  syncShowPastCheckbox() {
    $('input[name="past"]').prop('checked', this.showPast);
    const url = new URL(window.location.href);
    if (this.showPast) {
      $('select[name="month"]').prepend(this.pastDateOptions);
      url.searchParams.set('past', true);
      window.history.replaceState({}, '', url);
    } else {
      $('select[name="month"] option[data-past=true]').remove();
      url.searchParams.delete('past');
      window.history.replaceState({}, '', url);
    }
  }

  createPastToggleCheckbox() {
    const html = `
      <div class="govuk-form-group govuk-!-display-inline app-!-cancel-clearfix">
        <div class="govuk-checkboxes govuk-checkboxes--small">
          <div class="govuk-checkboxes__item">
            <input class="govuk-checkboxes__input" id="past" name="past" type="checkbox" value="1" ${
              this.showPast ? 'checked="true"' : ''
            }>
            <label class="govuk-label govuk-checkboxes__label" for="past">
              ${this.showPastAppointmentsText}
            </label>
          </div>
        </div>
      </div>
    `;
    this.container.find('.govuk-form-group').first().after(html);
  }

  toggleShowPast() {
    this.showPast = !this.showPast;
    this.syncShowPastCheckbox();
    const month = $('select[name="month"]').val();
    this.renderLoading();
    this.fetchAppointmentsForMonth(month);
  }

  onChange(e) {
    this.renderLoading();
    this.fetchAppointmentsForMonth(e.target.value);
  }

  fetchAppointmentsForMonth(month) {
    const url = new URL(window.location.href);
    url.searchParams.set('month', month);
    window.history.replaceState({}, '', url);

    $.ajax({
      url: '/appointments',
      type: 'get',
      data: { _format: 'json', month, past: this.showPast },
      success: (data) => this.onAjaxSuccess(data),
    });
  }

  onAjaxSuccess(data) {
    const appointments = data.data;
    !Object.keys(appointments).length
      ? this.renderNoAppointments()
      : this.renderAppointments(appointments);
  }

  renderAppointments(appointmentsInMonth) {
    const html = Object.keys(appointmentsInMonth).map((date) =>
      this.renderAppointmentDay(date, appointmentsInMonth[date])
    );
    this.resultsContainer.html(html.join(''));
  }

  renderAppointmentDay(date, appointments) {
    const formattedDate = new Intl.DateTimeFormat('id', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(new Date(date));

    const rowsHtml = appointments
      .map((appointment) => this.renderAppointmentRow(appointment))
      .join('');

    return `
      <div class="govuk-summary-card">
        <div class="govuk-summary-card__title-wrapper">
          <p class="govuk-!-margin-bottom-0 govuk-!-font-weight-bold">
            ${formattedDate}
          </p>
        </div>
        <div class="govuk-summary-card__content">
          <table class="govuk-table">
            <thead class="govuk-table__head">
              <tr class="govuk-table__row">
                <th scope="col" class="govuk-table__header appointment-list__table-header appointment-list__table-header--time">${this.timeText}</th>
                <th scope="col" class="govuk-table__header appointment-list__table-header">${this.nameText}</th>
                <th scope="col" class="govuk-table__header appointment-list__table-header">${this.appointmentIdText}</th>
                <th scope="col" class="govuk-table__header appointment-list__table-header">${this.appointmentTypeText}</th>
              </tr>
            </thead>
            <tbody class="govuk-table__body">
              ${rowsHtml}
            </tbody>
          </table>
        </div>
      </div>
    `;
  }

  renderAppointmentRow(appointment) {
    const time = new Intl.DateTimeFormat('id', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(appointment.datetime));

    return `
      <tr class="govuk-table__row">
        <td class="govuk-table__cell">${time}</td>
        <td class="govuk-table__cell">${appointment.name}</td>
        <td class="govuk-table__cell"><a href="/appointments/${appointment.id}">${appointment.id}</a></td>
        <td class="govuk-table__cell">${appointment.type}</td>
      </tr>
    `;
  }

  renderLoading() {
    this.resultsContainer.html('<p class="govuk-caption-m">Loading...</p>');
  }

  renderNoAppointments() {
    this.resultsContainer.html(`
      <div class="govuk-summary-card">
        <div class="govuk-summary-card__title-wrapper">
          <p class="govuk-!-margin-bottom-0 govuk-!-font-weight-bold">
            ${this.noAppointmentsText}
          </p>
        </div>
      </div>
    `);
  }
}
